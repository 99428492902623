@font-face {
	font-family: "icons";
	src: url('../fonts/icons1744118034886.eot');
	src: url('../fonts/icons1744118034886.eot?#iefix') format('eot'),
		url('../fonts/icons1744118034886.woff') format('woff'),
		url('../fonts/icons1744118034886.ttf') format('truetype'),
}

$icons: (

  angle-down: "\E001",

  angle-right: "\E002",

  arrow-left: "\E003",

  arrow-right: "\E004",

  bell: "\E005",

  checkmark: "\E006",

  clock: "\E007",

  close: "\E008",

  cogs: "\E009",

  coin-euro: "\E00A",

  copy: "\E00B",

  euro: "\E00C",

  eye-hide: "\E00D",

  eye: "\E00E",

  file-code: "\E00F",

  file-excel: "\E010",

  file-pdf-solid: "\E011",

  file-pdf: "\E012",

  invoice-ban: "\E013",

  invoice-dollar: "\E014",

  level-up: "\E015",

  list: "\E016",

  locked: "\E017",

  mask: "\E018",

  minus: "\E019",

  move-down: "\E01A",

  move-up: "\E01B",

  open: "\E01C",

  paper-plane: "\E01D",

  pencil: "\E01E",

  plus: "\E01F",

  print: "\E020",

  question: "\E021",

  recycle: "\E022",

  repeat: "\E023",

  search: "\E024",

  sign-out: "\E025",

  sitemap: "\E026",

  sort-asc: "\E027",

  sort-desc: "\E028",

  stopwatch: "\E029",

  toggle-off: "\E02A",

  toggle-on: "\E02B",

  trash: "\E02C",

  unlocked: "\E02D",

  upload-solid: "\E02E",

  user: "\E02F",

  warning: "\E030",

);

@function getIconChar($icon) {
  @if map-has-key($icons, $icon) {
    @return map-get($icons, $icon);
  }
}

.icon {
    font-family: "icons";
}

[class|=icon]{
    display: inline-block;
    &:before {
        position: relative;
        top: .1em;
        margin-right: .3em;
        font-family: icons;
        font-style: normal;
    }

    &.hide-text {
        font-size: 0;
        &:before {
            position: static;
            margin-right: 0;
            top: 0;
            font-size: $font-size-base;
        }
    }
}




.icon-angle-down:before {content: getIconChar(angle-down)}

.icon-angle-right:before {content: getIconChar(angle-right)}

.icon-arrow-left:before {content: getIconChar(arrow-left)}

.icon-arrow-right:before {content: getIconChar(arrow-right)}

.icon-bell:before {content: getIconChar(bell)}

.icon-checkmark:before {content: getIconChar(checkmark)}

.icon-clock:before {content: getIconChar(clock)}

.icon-close:before {content: getIconChar(close)}

.icon-cogs:before {content: getIconChar(cogs)}

.icon-coin-euro:before {content: getIconChar(coin-euro)}

.icon-copy:before {content: getIconChar(copy)}

.icon-euro:before {content: getIconChar(euro)}

.icon-eye-hide:before {content: getIconChar(eye-hide)}

.icon-eye:before {content: getIconChar(eye)}

.icon-file-code:before {content: getIconChar(file-code)}

.icon-file-excel:before {content: getIconChar(file-excel)}

.icon-file-pdf-solid:before {content: getIconChar(file-pdf-solid)}

.icon-file-pdf:before {content: getIconChar(file-pdf)}

.icon-invoice-ban:before {content: getIconChar(invoice-ban)}

.icon-invoice-dollar:before {content: getIconChar(invoice-dollar)}

.icon-level-up:before {content: getIconChar(level-up)}

.icon-list:before {content: getIconChar(list)}

.icon-locked:before {content: getIconChar(locked)}

.icon-mask:before {content: getIconChar(mask)}

.icon-minus:before {content: getIconChar(minus)}

.icon-move-down:before {content: getIconChar(move-down)}

.icon-move-up:before {content: getIconChar(move-up)}

.icon-open:before {content: getIconChar(open)}

.icon-paper-plane:before {content: getIconChar(paper-plane)}

.icon-pencil:before {content: getIconChar(pencil)}

.icon-plus:before {content: getIconChar(plus)}

.icon-print:before {content: getIconChar(print)}

.icon-question:before {content: getIconChar(question)}

.icon-recycle:before {content: getIconChar(recycle)}

.icon-repeat:before {content: getIconChar(repeat)}

.icon-search:before {content: getIconChar(search)}

.icon-sign-out:before {content: getIconChar(sign-out)}

.icon-sitemap:before {content: getIconChar(sitemap)}

.icon-sort-asc:before {content: getIconChar(sort-asc)}

.icon-sort-desc:before {content: getIconChar(sort-desc)}

.icon-stopwatch:before {content: getIconChar(stopwatch)}

.icon-toggle-off:before {content: getIconChar(toggle-off)}

.icon-toggle-on:before {content: getIconChar(toggle-on)}

.icon-trash:before {content: getIconChar(trash)}

.icon-unlocked:before {content: getIconChar(unlocked)}

.icon-upload-solid:before {content: getIconChar(upload-solid)}

.icon-user:before {content: getIconChar(user)}

.icon-warning:before {content: getIconChar(warning)}
